import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NavSkeleton = styled.div({
	display: 'flex',
	width: '100%',
	height: '56px',
	backgroundColor: token('color.skeleton', '#fff'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		content: '',
		position: 'absolute',
		left: 0,
		right: 0,
		top: '100%',
		height: '4px',
		background: token(
			'color.skeleton.subtle',
			'linear-gradient( 180deg, rgba(9, 30, 66, 0.13) 0, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px )',
		),
	},
});
